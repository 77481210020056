// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

// Components
import AuthButton from '../../authentication/AuthButton';

// i18n
import i18n from '../../i18n';

// Utils
import getLocalizedPath from '../../utils/getLocalizedPath';

// Assets
import LogotypeSVG from '../../assets/svg/logotype';

// Styles
import './_header.scss';

// Assets
import UserSVG from '../../assets/svg/user';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paths: {
        booking: getLocalizedPath('booking'),
        dashboard: getLocalizedPath('dashboard'),
        contact: getLocalizedPath('contact'),
        about: getLocalizedPath('about'),
        drinkAndEat: getLocalizedPath('restaurant'),
        market: getLocalizedPath('artisan'),
        catering: getLocalizedPath('catering'),
        events: getLocalizedPath('event'),
        promotions: getLocalizedPath('promotion'),
        // vip: getLocalizedPath('vip'),
        jobs: getLocalizedPath('job'),
        cafe: getLocalizedPath('cafe'),
        bar: getLocalizedPath('bar'),
      },
      toggled: false,
      fixed: false,
      isMobile: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.renderLink = this.renderLink.bind(this);
  }

  handleScroll() {
    if (window.scrollY > 80) {
      this.setState({
        fixed: true,
      });
    } else {
      this.setState({
        fixed: false,
      });
    }
  }

  handleToggle() {
    const { toggled } = this.state;

    this.setState({
      toggled: toggled ? false : true,
    });
  }

  handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 992) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  isBottom(el) {
    if (el)
      return (
        el.getBoundingClientRect().bottom + window.innerHeight <=
        window.innerHeight
      );
    return false;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('video');
    if (this.isBottom(wrappedElement)) {
      document.getElementById('mobileMenu').classList.add('opened');
    } else {
      document.getElementById('mobileMenu').classList.remove('opened');
    }
  };

  componentDidMount() {
    this.handleResize();
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleResize);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  renderLink(to = null, text = null, classes = 'site-header__link') {
    const { toggled } = this.state;
    const { pathname } = this.props;

    if (pathname === to && toggled) {
      return (
        <a href={to} className={classes}>
          {text}
        </a>
      );
    }
    return (
      <Link className={classes} to={to}>
        {text}
      </Link>
    );
  }

  render() {
    const {
      paths: {
        booking,
        // dashboard,
        contact,
        about,
        drinkAndEat,
        // market,
        catering,
        events,
        promotions,
        // vip,
        jobs,
        // cafe,
        bar,
      },
      toggled,
      fixed,
      isMobile,
    } = this.state;

    const { auth0, links, pathname } = this.props;
    const { isAuthenticated } = auth0;

    let headerClasses = 'site-header';
    if (toggled && isMobile) headerClasses += ' site-header--open';
    if (fixed) headerClasses += ' site-header--fixed';

    return (
      <header className={headerClasses}>
        <div className="site-header__container">
          <div className="site-header__logotype">
            {pathname === '/' && toggled && (
              <a to="/" className="navbar__brand">
                <LogotypeSVG />
              </a>
            )}
            {(pathname !== '/' || !toggled) && (
              <Link to="/" className="navbar__brand">
                <LogotypeSVG />
              </Link>
            )}
          </div>

          <div className="site-header__wrapper">
            <div className="site-header__row-top">
              <nav className="site-header__nav-primary">
                <ul className="site-header__menu site-header__menu--primary">
                  <li className="site-header__item">
                    {this.renderLink(
                      about,
                      i18n.t('concept'),
                      'site-header__link'
                    )}
                  </li>
                  {/*
                  <li className="site-header__item">
                    {this.renderLink(vip, i18n.t('vip_program'))}
                  </li>
                  */}
                  <li className="site-header__item">
                    <a
                      className="site-header__link"
                      href="https://districtgourmet.checkyourcardbalance.com/fr/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      title={i18n.t('gift_cards')}
                    >
                      {i18n.t('gift_cards')}
                    </a>
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(
                      contact,
                      i18n.t('contact'),
                      'site-header__link site-header__link--contact'
                    )}
                  </li>
                  <li className="site-header__item">
                    <a
                      className="site-header__link"
                      href="https://www.le-qg.ca/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t('the_gq')}
                    </a>

                    {/* <ul className="site-header__sub-menu">
                      <li className="site-header__item">
                        <a
                          className="site-header__link"
                          href="https://districtsante.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {i18n.t('district_health')}
                        </a>
                      </li>
                    </ul> */}
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(
                      jobs,
                      i18n.t('jobs'),
                      'site-header__link site-header__link--jobs'
                    )}
                  </li>
                </ul>
              </nav>
              <nav className="site-header__nav-login">
                <ul className="site-header__menu site-header__menu--login">
                  <li className="site-header__item site-header__has-sub-menu">
                    <a href="#" className="site-header__link user-header">
                      <UserSVG />
                    </a>
                    <ul className="site-header__sub-menu top">
                      {isAuthenticated && (
                        <li className="site-header__item">
                          <a
                            className="site-header__link"
                            href="https://gourmet.le-qg.ca/profil"
                            target="_blank"
                            title={i18n.t('account').title}
                            rel="noreferrer"
                          >
                            {i18n.t('account').title}
                          </a>
                        </li>
                      )}
                      {!isAuthenticated && (
                        <li className="site-header__item">
                          <AuthButton login mode="signUp">
                            {i18n.t('account').sign_in}
                          </AuthButton>
                        </li>
                      )}
                      <li className="site-header__item">
                        {!isAuthenticated && (
                          <AuthButton login>
                            {i18n.t('account').login}
                          </AuthButton>
                        )}
                        {isAuthenticated && (
                          <AuthButton login={false}>
                            {i18n.t('account').logout}
                          </AuthButton>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="site-header__row-bottom">
              <nav className="site-header__nav-contact">
                <ul className="site-header__menu site-header__menu--contact">
                  <li className="site-header__item">
                    {this.renderLink(drinkAndEat, i18n.t('menu').restaurants)}
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(bar, 'Bar à cocktails')}
                  </li>
                  <li className="site-header__item">
                    {/* this.renderLink(market, i18n.t('market')) */}
                    <a
                      className="site-header__link"
                      href="https://marchecarrier.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.t('market')}
                    </a>
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(catering, i18n.t('catering').title)}
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(events, i18n.t('events').title)}
                  </li>
                  <li className="site-header__item">
                    {this.renderLink(promotions, i18n.t('promotions'))}
                  </li>
                </ul>
              </nav>

              <nav className="site-header__nav-accent">
                <ul className="site-header__menu site-header__menu--accent">
                  <li className="site-header__item site-header__item--book">
                    {/*
                    <a
                      className="site-header__link"
                      href={links.book}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={i18n.t('book')}
                    >
                      {i18n.t('book')}
                    </a>
                    */}
                    {this.renderLink(
                      booking,
                      i18n.t('book'),
                      'site-header__link'
                    )}
                  </li>
                  <li className="site-header__item site-header__item--order">
                    <a
                      className="site-header__link"
                      href="/commande-en-ligne.html"
                      rel="noopener noreferrer"
                      title={i18n.t('order')}
                    >
                      {i18n.t('order')}
                    </a>
                  </li>
                  {/*
                  <li className="site-header__item site-header__item--grocery">
                    <a
                      className="site-header__link"
                      href={links.grocery}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={i18n.t('online_grocery')}
                    >
                      {i18n.t('online_grocery')}
                    </a>
                  </li>
                  */}
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <button
          className="site-header__burger"
          type="button"
          onClick={this.handleToggle}
        >
          <div className="site-header__burger-wrapper" aria-label="Fermer">
            <span className="sr-only">{i18n.t('navbar_toggle_sr')}</span>
            <span className="site-header__burger-bar site-header__burger-bar--top" />
            <span className="site-header__burger-bar site-header__burger-bar--middle" />
            <span className="site-header__burger-bar site-header__burger-bar--bottom" />
          </div>
        </button>
        <nav className="site-header__nav-accent mobile-menu" id="mobileMenu">
          <ul className="site-header__menu site-header__menu--accent">
            <li className="site-header__item site-header__item--book">
              {/*
              <a
                className="site-header__link"
                href={links.book}
                target="_blank"
                rel="noopener noreferrer"
                title={i18n.t('book')}
              >
                {i18n.t('book')}
              </a>
              */}
              {this.renderLink(booking, i18n.t('book'), 'site-header__link')}
            </li>
            <li className="site-header__item site-header__item--order">
              <a
                className="site-header__link"
                href="/commande-en-ligne.html"
                rel="noopener noreferrer"
                title={i18n.t('order')}
              >
                {i18n.t('order')}
              </a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  links: PropTypes.shape({
    order: PropTypes.string,
    book: PropTypes.string,
    grocery: PropTypes.string,
  }),
  pathname: PropTypes.string,
};

Header.defaultProps = {
  links: {
    order: '',
    book: '',
    grocery: '',
  },
  pathname: '',
};

export default withAuth0(Header);
