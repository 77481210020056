// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// Components
import Hero from '../components/Hero';
import Layout from '../components/Layout';

// Utils
import { getBooking } from '../utils/wordpressApi';
import scrollToElement from '../utils/scrollToElement';

class Booking extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: [
        {
          text: '',
          title: '',
          video: '',
          links: [{ label: 'test', url: '#' }],
        },
      ],
      hero: {
        title: '',
      },
      metatags: {},
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Get anchor
    const {
      location: { state: locationState },
    } = this.props;

    let anchor = null;

    if (
      !!locationState &&
      Object.prototype.hasOwnProperty.call(locationState, 'anchor')
    ) {
      const { anchor: locationAnchor } = locationState;
      anchor = locationAnchor;
    }

    // Fetch Wordpress Data
    const data = await getBooking();

    if (this._isMounted && !!data) {
      const { content, hero, metatags } = data;

      this.setState(prevState => ({
        ...prevState,
        content,
        hero,
        metatags,
      }));

      // Handle anchor
      if (anchor !== null) {
        setTimeout(() => {
          scrollToElement(anchor, false);

          // Unset loading
          unsetEvent('loading');
        }, 500);
      }
    }

    if (anchor === null) {
      // Unset loading
      unsetEvent('loading');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { content, hero, metatags } = this.state;

    return (
      <Layout className="booking-page" metatags={metatags}>
        <Hero centered title={hero.title} />

        {!!content && (
          <section>
            {content.map(
              ({ links = [], text = '', title = '', video = '' }) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <article className="content-block">
                    <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 col-xxxl-6 offset-xxxl-3">
                      {!!title && (
                        <h2 className="content-block__title">{title}</h2>
                      )}

                      {!!text && (
                        <div
                          className="content-block__text"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      )}
                    </div>

                    {!!video && (
                      <div className="content-block__video">
                        <iframe
                          title={hero.title}
                          className="text-image-under__iframe"
                          src={`${video}?color=5a212c&title=0&byline=0&fun=0&dnt=true`}
                          allow="autoplay; fullscreen"
                          allowFullScreen
                        />
                      </div>
                    )}
                    {!!links && (
                      <div className="content-block__links">
                        {links.map(item => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <a className="btn btn-outline" href={item.url}>
                              {item.label}
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </article>
                );
              }
            )}
          </section>
        )}
      </Layout>
    );
  }
}

Booking.contextType = AppContext;

export default withTranslation()(Booking);
