// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

import i18n from '../i18n';

// Components
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import TextFullWidth from '../components/TextFullWidth';
import TextImage from '../components/TextImage';
import TextImageUnder from '../components/TextImageUnder';

// Utils
import { getAbout } from '../utils/wordpressApi';
import scrollToElement from '../utils/scrollToElement';
import getLocalizedPath from '../utils/getLocalizedPath';

class About extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      concept: {
        text: '',
        title: '',
      },
      district: {
        image: { sizes: { landscapeWideLg: '', portraitSm: '' }, title: '' },
        text: '',
        title: '',
        video: '',
      },
      eat: {
        image: { sizes: { portraitLg: '', portraitSm: '' }, title: '' },
        text: '',
        title: '',
      },
      hero: {
        thumbnail: {
          sizes: { landscapeSm: '', landscapeWideLg: '', xl: '' },
          title: '',
        },
        title: '',
      },
      metatags: {},
      takeout: {
        image: { sizes: { portraitLg: '', portraitSm: '' }, title: '' },
        text: '',
        title: '',
      },
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Get anchor
    const {
      location: { state: locationState },
    } = this.props;

    let anchor = null;

    if (
      !!locationState &&
      Object.prototype.hasOwnProperty.call(locationState, 'anchor')
    ) {
      const { anchor: locationAnchor } = locationState;
      anchor = locationAnchor;
    }

    // Fetch Wordpress Data
    const data = await getAbout();

    if (this._isMounted && !!data) {
      const { concept, district, eat, hero, metatags, takeout } = data;

      this.setState(prevState => ({
        ...prevState,
        concept,
        district,
        eat,
        hero,
        metatags,
        takeout,
      }));

      // Handle anchor
      if (anchor !== null) {
        setTimeout(() => {
          scrollToElement(anchor, false);

          // Unset loading
          unsetEvent('loading');
        }, 500);
      }
    }

    if (anchor === null) {
      // Unset loading
      unsetEvent('loading');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      concept,
      district,
      eat,
      hero,
      metatags,
      takeout,
    } = this.state;

    const {
      options: {
        links: { book: bookLink, order: orderLink },
      },
    } = this.context;

    return (
      <Layout className="about-page" metatags={metatags}>
        <Hero centered title={hero.title} />

        {/* {!!district.video && (
          <div className="hero__video">
            <video autoPlay={"autoplay"} muted loop preload="auto" id="myVideo">
              <source src={district.video} type="video/mp4"/>
            </video>
          </div>
        )} */}

        {!!district.video && (
          <div className="hero__video">
            <iframe
              title={district.title}
              className="text-image-under__iframe"
              src={`${district.video}?color=5a212c&title=0&byline=0&fun=0&dnt=true`}
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        )}

        <TextImageUnder
          image={{
            sizes: {
              desktop: district.image.sizes.landscapeWideLg,
              mobile: district.image.sizes.portraitSm,
            },
            title: district.image.title,
          }}
          surtitle={i18n.t('the_district_gourmet')}
          text={district.text}
          title={district.title}
          video={district.video}
        />

        <TextImage
          centered={false}
          image={{
            sizes: {
              desktop: eat.image.sizes.portraitLg,
              mobile: eat.image.sizes.portraitSm,
            },
            title: eat.image.title,
          }}
          text={eat.text}
          title={eat.title}
        />

        <TextFullWidth text={concept.text} title={concept.title} />

        <TextImage
          button={{
            text: 'Commande en ligne',
            href: orderLink,
          }}
          centered={false}
          image={{
            sizes: {
              desktop: takeout.image.sizes.portraitLg,
              mobile: takeout.image.sizes.portraitSm,
            },
            title: takeout.image.title,
          }}
          reversed
          text={takeout.text}
          title={takeout.title}
        />
      </Layout>
    );
  }
}

About.contextType = AppContext;

export default withTranslation()(About);
