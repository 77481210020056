// Vendors
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AppContextProvider from './context';

import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from './i18n';
import routes from './routes';
import ScrollToTop from './utils/scrollToTop';

// Pages
import About from './pages/about';
import ArtisanSingle from './pages/artisanSingle';
import Artisan from './pages/artisan';
import Blog from './pages/blog';
import BlogSingle from './pages/blogSingle';
import Booking from './pages/booking';
import Catering from './pages/catering';
import Contact from './pages/contact';
import ContestSingle from './pages/contestSingle';
import Contest from './pages/contest';
// import ClientService from './pages/clientService';
// import Consent from './pages/account/consent';
// import Dashboard from './pages/account/dashboard';
// import EmailValidation from './pages/account/emailValidation';
import EventSingle from './pages/eventSingle';
import Error404 from './pages/error404';
import Event from './pages/event';
// import EventSpace from './pages/eventSpace';
import Faq from './pages/faq';
import Home from './pages/index';
import JobSingle from './pages/jobSingle';
import Job from './pages/job';
// import Menu from './pages/menu';
import Order from './pages/order';
// import PairingForm from './pages/account/pairingForm';
import PrivacyPolicy from './pages/privacyPolicy';
// import ProfileToCompleteForm from './pages/account/profileToCompleteForm';
import PromotionSingle from './pages/promotionSingle';
import Promotion from './pages/promotion';
// import RegistrationForm from './pages/account/registrationForm';
import RestaurantSingle from './pages/restaurantSingle';
import Restaurant from './pages/restaurant';
import RestaurantCat from './pages/restaurantCat';
import TermsAndConditions from './pages/termsAndConditions';
import TermsAndConditionsCard from './pages/termsAndConditionsCard';
// import Vip from './pages/vip';
// import NosMenus from './pages/nosMenus';
import Cafe from './pages/cafe';
import Bar from './pages/bar';

const AppRouter = () => {
  const components = {
    Home,
    RestaurantSingle,
    Restaurant,
    RestaurantCat,
    ArtisanSingle,
    Artisan,
    BlogSingle,
    Blog,
    // Consent,
    // Dashboard,
    // EmailValidation,
    // RegistrationForm,
    EventSingle,
    Event,
    Order,
    // PairingForm,
    PrivacyPolicy,
    // ProfileToCompleteForm,
    PromotionSingle,
    Promotion,
    ContestSingle,
    Contest,
    JobSingle,
    Job,
    About,
    Catering,
    // EventSpace,
    // Menu,
    TermsAndConditions,
    TermsAndConditionsCard,
    // ClientService,
    Contact,
    Faq,
    // Vip,
    // NosMenus,
    Cafe,
    Bar,
    Booking,
  };

  const generatedRoutes = [];

  routes.forEach(({ component: componentName, exact, key, paths }) => {
    AVAILABLE_LOCALES.forEach(locale => {
      let path = paths[locale];

      if (path && components[componentName]) {
        if (locale !== DEFAULT_LOCALE) {
          path = `/${locale}${path}`;
        }

        generatedRoutes.push(
          <Route
            component={components[componentName]}
            exact={exact ? true : false}
            key={key}
            path={path}
          />
        );
      }
    });
  });

  generatedRoutes.push(<Route key="404" component={Error404} status={404} />);

  return (
    <Router>
      <ScrollToTop />
      <AppContextProvider>
        <Switch>{generatedRoutes}</Switch>
      </AppContextProvider>
    </Router>
  );
};

export default AppRouter;
